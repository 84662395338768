@font-face {
    font-family: Nunito;
    /* src: url(../construction/Nunito/Nunito-VariableFont_wght.ttf) */
}

.project_plan_section {
    font-family: Nunito, sans-serif;
}

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.header {
    background-image: url(./images/bg_10.jpg);
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
    padding: 10px 100px;
}

nav {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;

}


nav ul li a {
    list-style-type: none;
    text-decoration: none;
    display: inline-block;
    /* padding: 25px 15px; */
    font-size: 13px;
    font-family: nunito, sans-serif;
}

.logo {
    height: 30px;
    width: 130px;
}
 
.logo img {
    width: 100%;

}
nav a:hover {
    color: #4f46e5;
}

nav ul li {
    display: inline-block;
}

.nav_btn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 25px 0px;
}

.nav_btn_one {
    margin-right: 7px;
    height: 36px;
    width: 36px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: 4f46e50d;

}

.nav_btn_two {
    background-color: #4f46e5;
    height: 36px;
    width: 36px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header_texts {
    display: flex;
    flex: 1;
    height: 326px;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
}

.header_content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.header p {
    font-family: nunito;
    font-size: 13px;
    color: black;
}

.first_paragraph {
    width: 712.66px;
    height: 144px;
    font-size: 48px;
    font-family: nunito, sans-serif;
}

.first_paragraph p {
    font-size: 18px;
    font-family: nunito, sans-serif;
    width: 576px;
    height: 56px;
}

.Dream_home {
    margin-bottom: 20px;
}

.second_paragraph p {
    font-size: 18px;
    font-family: nunito, sans-serif;
}

.contact_btn button {
    background-color: #4f46e5;
    padding: 8px 20px;
    /* width:125.98px;
 height:42px; */
    font-size: 16px;
    color: white;
    border: 1px solid #4f46e5;
    border-radius: 4px;
}

.video_btn {
    display: flex;
    width: 100%;
    margin-top: 24px;
    justify-content: center;
    align-items: center;
    margin-left: 150px;
}

.video_btn_icon {
    display: flex;
    background-color: white;
    height: 96px;
    width: 96px;
    border: 1px solid white;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
}

.project_plan_section {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    justify-content: center;
    margin-top: -20px;
}

.project_plan_wrapper {
    display: flex;
    flex-direction: row;
    background-color: white;
    border: 1px solid rgb(141, 137, 137);
    border-radius: 6px;

}

.white_section {
    display: flex;
    flex-direction: row;
    background-color: white;
    padding: 30px;
    height: max-content;

}

.project_plan {
    display: flex;
    flex-direction: column;
    margin-left: 1px;
}

.plan_top_content {
    display: flex;
    flex-direction: row;
    width: 328.33px;
    height: 118px;
}

.project_planning {
    width: 256.33px;
    height: 106px;
}

.project_planning p {
    margin-top: 12px;
    font-size: 16px;
}

.project_planning h4 {
    font-size: 18px;
}

.icon_section {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background-color: #4f46e5;
    border: 1px solid white;
    border-radius: 50%;
    margin-right: 16px;

}

.plan_bottom_content {
    display: flex;
    flex-direction: row;
    width: 328.33px;
    height: 118px;
    margin-top: 10px;
}

.Experience_section {
    background-color: #4f46e5;
    padding: 30px;
    /* width:312.34px;
    height:320px; */
}

.Experience_content {
    background-color: white;
    color: black !important;
    padding: 10px;

}

.Experience_top_content span {
    font-size: 20px;
    font-family: Nunito, sans-serif;
    /* color: white; */
    width: 91.58px;
    height: 27px;
}

.Experience_mid_content h4 {
    width: 313.34px;
    height: 64px;
    margin: 12px 0px;
    font-size: 24px;
    /* color: white; */
    font-family: nunito, sans-serif;
}

.Experience_bottom_content p {
    width: 313.34px;
    height: 78px;
    font-size: 16px;
    font-family: nunito, sans-serif;
    /* color: white; */
}

.About_us_btn button {
    background-color: #4f46e5;
    padding: 8px 20px;
    color: white;
    font-size: 16px;
    border: 1px solid #4f46e5;
    border-radius: 6px;
    margin-top: 24px 0px 0px;
}

.About_company_section {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    margin-top: 96px;
    justify-content: center;
    padding: 0px 80px;
}

.About_company_images img {
    width: 400.44px;
    height: 450.16px;
    padding: 0px 48px 0px 0px;

}

.construction_worker_image {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 225px;
    height: 225px;
    margin-right: 0px;
    position: absolute;
    bottom: 50px;
    right: 0px;
}

.about_company_section_wrapper {
    position: relative;
}

.construction_worker_image img {
    width: 100%;
    height: 100%;
}

.About_company_Video_btn button {
    display: flex;
    height: 30px;
    width: 30px;
    border: 1px solid #4f46e5;
    border-radius: 50%;
    background-color: #4f46e5;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.About_company_Video_btn {
    display: flex;
    flex-direction: row;
    justify-content: right;
    /* margin-top: -113px;
    margin-right: -18px; */
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.About_company_container {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    width: 100%;
    height: 100%;
    margin-left: 50px;
}

/* .About_company_wrapper{
width:624.84px ;
height:450.13px;
 margin-top:25px;
} */

.About_company_btn button {
    background-color: #4f46e5;
    padding: 2px 10px;
    color: white;
    font-family: nunito, sans-serif;
    font-size: 12px;
    border: 1px solid #4f46e5;
    border-radius: 10px;
}

.About_company_texts {
    width: 624.84px;
    height: 100%;
    margin-top: 25px;

}

.About_company_texts h4 {
    font-size: 30px;
    font-family: nunito, sans-serif;
    margin: 16px 0px 24px;
    color: #161c2d;
}

.About_company_texts p {
    color: #94a3b8;
    font-size: 16px;
    font-family: nunito, sans-serif;
}

button, a {
    cursor: pointer;
}

.About_company_read_more_btn button {
    margin: 24px 0px;
    padding: 8px 20px;
    font-size: 16px;
    color: white;
    background-color: #4f46e5;
    border: 1px solid #4f46e5;
    border-radius: 10px;
}

.projects_completed {
    display: flex;
    flex-direction: row;
    margin: 24px 0px 0px;
    padding: 24px 0px 0px;
    justify-content: space-between;
}

.project_completed p {
    margin: 0px 0px 8px;
    font-size: 36px;
    color: #161c2d;
    font-family: nunito, sans-serif;
}

.project_completed h5 {
    font-size: 18px;
    color: #161c2d;
    font-family: nunito, sans-serif;
}

.years_of_exprience p {
    margin: 0px 0px 8px;
    font-size: 36px;
    color: #161c2d;
    font-family: nunito, sans-serif;
}

.years_of_exprience h5 {
    font-size: 18px;
    color: #161c2d;
    font-family: nunito, sans-serif;
}

.affilates {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    height: max-content;
    margin-top: 64px;
    padding: 0px 80px;
}

.affilates img {
    width: 72.31px;
    height: 24px;
}

.projects_portfolio {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: max-content;
    justify-content: center;
    align-items: center;
    margin-top: 96px;
    padding: 0px 80px;
}

.projects_text {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: max-content;
    justify-content: center;
    align-items: center;
    padding: 0px 0px 32px;
}

.projects_text h3 {
    font-size: 30px;
    font-family: nunito, sans-serif;
    margin: 0px 0px 16px;
    color: #161c2d;
}

.projects_text p {
    font-size: 16px;
    font-family: nunito, sans-serif;
    color: #94a3b8;
    width: 576px;
    height: 52px;
    text-align: center;
}

.project_works {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: max-content;
    flex-wrap: wrap;
    margin-top: 32px 0px 0px;
    justify-content: space-around;
}

.project_works img {
    width: 353.33px;
    height: 264.98px;
    border-radius: 6px;
}

.project_works_photos a h5 {
    padding: 12px 0px 0px;
    font-size: 16px;
    font-family: nunito, sans-serif;
    margin: 0px 0px 2px;
    color: #161c2d;

}

.project_works_photos a {
    text-decoration: none;
}

.project_works_photos a:hover h5 {
    color: #4f46e5;

}

.project_works_photos h6 {
    margin: 0px 0px 25px;
    font-size: 16px;
    font-family: nunito;
    color: #94a3b8;
}

.Company_goal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url(./images/bg2_1.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    padding: 96px 0px;
    margin: 96px 0px;
    background-attachment: fixed;
}

.logo_img_div {
    width: 130px;
}

.logo_img_div  img {
    width: 100%;
}

.Company_goal_text {
    display: flex;
    flex-direction: column;
    width: 100px;
    justify-content: center;
    align-items: center;
    height: 100%;

}

.Company_goal_text h3 {
    text-align: center;
    width: 1120px;
    height: 90px;
    color: black;
    font-family: nunito, sans-serif;
    font-size: 30px;
    margin: 0px 0px 16px;

}

.Company_goal_text p {
    text-align: center;
    width: 576px;
    height: 52px;
    color: black;
    font-family: nunito, sans-serif;
    font-size: 16px;

}

.Company_goal_video_btn {}

.Company_goal_video_btn button {
    display: flex;
    flex-direction: row;
    background-color: white;
    justify-content: center;
    align-items: center;
    height: 80px;
    width: 80px;
    border-radius: 50%;
    margin: 40px 0px;
}

.Management_Team {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* width:100% ; */
    height: 100%;
    padding: 0px 80px;
    margin: 96px 0px;
}

.Management_Team_Text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    text-align: center;
}

.Management_Team_Text h6 {
    font-size: 16px;
    color: #4f46e5;
    font-family: nunito, sans-serif;
    margin: 0px 0px 8px;
    height: 24px;
    width: 1120px
}

.Management_Team_Text h3 {
    font-size: 30px;
    color: #161c2d;
    font-family: nunito, sans-serif;
    margin: 0px 0px 24px;
    height: 45px;
    width: 1120px
}

.Management_Team_Text p {
    font-size: 16px;
    color: #94a3b8;
    font-family: nunito, sans-serif;
    padding: 0px 0px 32px;
    width: 576px;
    height: 52px;
}

.Management_Team_Members {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    height: max-content;
    margin: 32px 0px 0px;
}

.Management_Team_Members img {
    border-radius: 50%;
    width: 204px;
    height: 204px;
}

.Management_Team_photos {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: max-content;
    justify-content: center;
    align-items: center;
}

.Management_Team_photos a {
    color: #161c2d;
    text-decoration: none;
    font-size: 18px;
    font-family: Nunito, sans-serif;
    margin-top: 12px 0px 0px;
}

.Management_Team_photos p {
    color: #94a3b8;
    text-decoration: none;
    font-size: 16px;
    font-family: Nunito, sans-serif;
    margin-top: 12px 0px 0px;
}

.Blog_section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: max-content;
    /* margin: 0px 80px; */
    /* padding: 0px 80px; */
}

.Blog_Text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* height: max-content; */
    padding: 0px 0px 32px;
}

.Blog_Text h6 {
    color: #4f46e5;
    font-size: 16px;
    margin-bottom: 0px 0px 12px;
    font-family: nunito, sans-serif;
}

.Blog_Text h3 {
    color: #161c2d;
    font-size: 30px;
    margin-bottom: 0px 0px 24px;
    font-family: nunito, sans-serif;
}

.Blog_Text p {
    color: #94a3b8;
    font-size: 16px;
    font-family: nunito, sans-serif;
    width: 576px;
    height: 52px;
    text-align: center;
}

.Blog_images {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: max-content;
    justify-content: space-around;
    align-items: center;
    margin: 32px 0px 0px;
}

.Blog_images img {
    width: 353.33px;
    height: 234.08px;
    border-radius: 6px;
}

.Blog_photos_text {
    /* height:181.13px ; */
    width: 353.33px;
    padding: 24px;
}

.Blog_photos_text a,
h5 {
    color: #161c2d;
    text-decoration: none;
    font-size: 18px;
    font-family: nunito, sans-serif;
}

.Blog_photos_text h5 {
    color: #161c2d;
}

.Blog_photos_text a:hover h5 {
    color: #4f46e5;
}

.Blog_photos_text p {
    margin: 12px 0px 0px;
    font-size: 16px;
    font-family: nunito, sans-serif;
    color: #94a3b8;
}

.Blog_photos_text button,
a {
    font-family: nunito, sans-serif;
    font-size: 16px;
    margin: 16px 0px 0px;
    border: none;
    color: #161c2d;
}

.Blog_photos_text button a:hover {
    color: #4f46e5;
}

.footer_Section {
    display: flex;
    flex-direction: column;
    background-color: #192132;
    margin-top: 96px;
    width: 100%;
}

.footer_Section_wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: max-content;
    /* margin: 0px 80px; */
    padding: 0px 80px;
}

.footer_content {
    display: flex;
    flex-direction: row;
    padding: 60px 0px;
    justify-content: space-between;
    /* height: max-content; */
    width: 100%;

}

.Logo_section {
    display: flex;
    flex-direction: column;
    width: 353.33px;
    height: 276px;
}

.Logo_section p {
    color: #d1d5db;
    font-size: 16px;
    margin: 24px 0px 0px;
    font-family: nunito, sans-serif;
}

.company_details,
h5 {
    color: #f3f4f6;
    font-family: nunito, sans-serif;
}

.list_items {
    margin: 24px 0px 0px;
    color: #d1d5db;
    font-family: nunito, sans-serif;
    font-size: 16px;
}

.list_items ul li {
    margin: 10px 0px 0px;
    list-style-type: none;
}

.Newsletter_content {
    margin-top: 24px 0px 0px;
    width: 257.5px;
    height: 276px;
}

.Newsletter_content p {
    color: #e5e7eb;
    font-size: 16px;
    font-family: nunito, sans-serif;
}

.Newsletter_content label {
    font-size: 16px;
    font-family: nunito, sans-serif;
    color: #e5e7eb;
}

#emails {
    font-family: nunito, sans-serif;
    font-size: 16px;
    background: #1f2937;
    padding: 8px 12px 8px 48px;
    border-radius: 6px;
    border: none;
    color: #e5e7eb;
}

.Personal_Details {
    margin: 12px 0px;
}

.Newsletter_content button {
    padding: 8px 95px;
    background-color: #4f46e5;
    color: #e5e7eb;
    font-family: nunito, sans-serif;
    font-size: 16px;
    border-radius: 6px;
}

.Footer_Sponsors_Section {
    display: flex;
    flex-direction: row;
    /* height: max-content; */
    padding: 30px 0px;
}

.Footer_Sponsors_content p {
    color: #e5e7eb;
    font-size: 16px;
    font-family: nunito, sans-serif;
}

.Footer_Sponsors_content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin: 0px 80px;
    padding: 0px 80px;
}

.hide_in_laptop_view {
    display: none;
}


/* _________________------------------__________________------------- */
/* for hamburger */


 @media screen and (max-width:950px) {
 
    .hide_in_mobile_view {
        display: none;
    }

    .hide_in_laptop_view {
        display: flex;
    }
    .video_btn {
        margin-left: 0px;
    }

    .header_content {
        flex-direction: column;
    }

    .header_texts {
        width: 100%;
    }

    .first_paragraph {
        width: 100%;
        height: max-content;
    }

    .project_plan_section {
        flex-direction: row;
        margin-top: -130px;
    }

    .project_plan_wrapper {
        flex-direction: column;

    }

    .About_company_section {

        flex-direction: column;
    }

    .construction_worker_image img {
        margin-right: 282px;
    }

    .About_company_Video_btn button {
        margin-right: 329px;
    }

    .About_company_texts {
        margin-top: 74px;
    }

    .About_company_container {
        padding-left: 2px;
        margin-left: 0px;
    }

    .Company_goal {
        padding: 200px 0px;
    }

    .Management_Team_Members {
        flex-direction: column;
    }

    .Management_Team_photos {
        margin-bottom: 24px;
    }

    .Blog_images {
        flex-direction: column;
        margin: 30px 0px 0px;
    }

    .footer_content {
        flex-direction: column;
        padding: 40px 0px;
        height: max-content;
        width: 100%;
    }

    .Logo_section {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: max-content;
    }

    .Footer_Sponsors_content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin: 0px 20px;
        padding: 0px 40px;
    }

    .company_details,
    h5 {
        margin-top: 20px;
        color: #f3f4f6;
        font-family: nunito, sans-serif;
    }

    .Newsletter_content {
        margin-top: 20px;
        width: 100%;
        height: max-content;
    }

    #emails {
        font-family: nunito, sans-serif;
        font-size: 16px;
        background: #1f2937;
        border-radius: 6px;
        border: none;
        color: #e5e7eb;
        padding: 8px 45px;
    }
}

   


@media screen and (max-width: 450px) {
    .hide_in_mobile_view {
        display: none;
    }

    .hide_in_laptop_view {
        display: flex;
    }
    .header {
        padding: 10px 10px;
    }

    .first_paragraph {
        width: 100%;
        height: max-content;
        font-size: 28px;
    }

    .header_content {
        flex-direction: column;
    }

    .first_paragraph p {
        font-size: 17px;
        font-family: nunito, sans-serif;
        width: 100%;
        height: max-content;
    }

    .contact_btn button {
        margin-top: 18px;
        font-size: 13px;
    }

    .video_btn {
        display: flex;
        width: 100%;
        margin-top: 0px;
        justify-content: center;
        align-items: center;
        margin-left: 0px;
    }

    .video_btn_icon {
        height: 50px;
        width: 50px;
    }

    .project_plan_section {
        flex-direction: column;
        margin-top: -16px;
    }


    .project_plan_wrapper {
        flex-direction: column;
        border-radius: 3px;
    }

    .plan_bottom_content {
        width: 100%;
        height: 100%;
        margin-top: 11px;
        margin-bottom: 23px;
    }

    .icon_section {
        width: 37px;
        height: 37px;
    }

    .project_planning {
        width: 100%;
        height: max-content;
    }

    .project_planning p {
        margin-top: 12px;
        font-size: 13px;
    }

    .white_section {
        display: flex;
        flex-direction: column;
        padding: 23px;
        height: max-content;
    }

    .plan_top_content {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: max-content;
    }

    .project_planning h4 {
        font-size: 17px;
    }

    .Experience_mid_content h4 {
        width: 100%;
        height: max-content;
        margin: 12px 0px;
        font-size: 20px;
    }

    .Experience_bottom_content p {
        width: 100%;
        height: max-content;
        font-size: 13px;
    }

    .About_us_btn button {
        font-size: 12px;
        margin-top: 12px;
    }

    .Experience_top_content_span {
        font-size: 18px;
    }

    .About_company_Video_btn {
        display: flex;
        width: 100%;
        height: max-content;
    }

    .About_company_texts {
        width: 100%;
        height: max-content;
        margin-top: 98px;
    }

    .construction_worker_image img,
    .construction_worker_image {
        width: 150px;
        height: 150px;
        margin-right: 0px;
    }

    .About_company_section {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        margin-top: 96px;
        justify-content: center;
        align-items: center;
        padding: 0px 44px;
    }

    .About_company_Video_btn {
        display: flex;
        flex-direction: row;
        justify-content: right;
        margin-top: -76px;
        margin-left: -45px;
        width: 100%;
        height: max-content;

    }

    .About_company_texts h4 {
        font-size: 23px;
    }

    .About_company_texts p {
        font-size: 12px;
    }

    .project_completed p {
        font-size: 21px;
    }

    .years_of_exprience p {
        font-size: 21px;
    }

    .project_completed h5 {
        font-size: 14px;
    }

    .years_of_exprience h5 {
        font-size: 14px;
    }

    .affilates img {
        width: 40px;
    }

    .About_company_container {
        margin-left: 0px;
        padding-left: 0px;
    }

    .About_company_images img {
        width: 100%;
        padding: 0px;
    }

    .projects_portfolio {
        margin-top: 60px;
        padding: 0px 23px;
    }

    .projects_text p {
        width: 100%;
        height: max-content;
    }

    .project_works_photos h6 {
        text-align: center;
        margin: 0px 0px 20px;
        font-size: 14px;
    }

    .project_works_photos a h5 {
        text-align: center;
    }

    .project_works img {
        width: 100%;
        border-radius: 6px
    }

    .Company_goal {
        width: 100%;
        height: max-content;
        padding: 0px 23px;
        margin: 63px 0px;
    }

    .Company_goal_text {
        padding-top: 50px;
        width: 100%;
        height: max-content;
    }

    .Company_goal_text h3 {
        width: 100%;
        height: max-content;
        font-size: 21px;
        margin: 0px 0px 10px;
        padding-top: 19px;
    }

    .Company_goal_text {
        width: 100%;
        height: max-content;
    }

    .Company_goal_text p {
        width: 100%;
        height: max-content;
        font-size: 16px;
    }

    .Company_goal_video_btn {
        margin-top: 15px;
        margin-bottom: 24px;
    }

    .Company_goal_video_btn button {
        height: 60px;
        width: 60px;
    }

    .Management_Team_Members {
        flex-direction: column;
        width: 100%;
        height: max-content;
        margin: 42px 0px 0px;
    }

    .Management_Team_Text p {
        font-size: 15px;
        padding: 0px 0px 27px;
        width: 100%;
    }

    .Management_Team_Members img {
        width: 100%;
    }

    .Management_Team_Text h3 {
        font-size: 28px;
        margin: 0px 0px 9px;
        height: max-content;
        width: 100%;
    }

    .Management_Team_photos {
        margin-bottom: 17px;
        width: 100%;
        height: max-content;
    }

    .Management_Team_Text h6 {
        font-size: 16px;
        margin: 0px 0px 8px;
        height: max-content;
        width: 100%;
    }

    .Management_Team {
        width: 100%;
        height: max-content;
        padding: 0px 23px;
        margin: 72px 0px;
    }

    .Blog_Text p {
        font-size: 15px;
        width: 100%;
        height: max-content;
    }

    .Blog_Text {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: max-content;
        padding-bottom: 0px;
    }

    .Blog_section {
        width: 100%;
        height: max-content;
        margin: 0px;
        padding: 0px 23px;
    }

    .Blog_images img {
        width: 100%;
        height: max-content;
        border-radius: 6px;
    }

    .Blog_images {
        flex-direction: column;
        width: 100%;
        height: max-content;
        margin: 32px 0px;
    }

    .Blog_photos_text {
        height: max-content;
        width: 100%;
        padding: 16px;
    }

    .Blog_photos_text p {
        margin: 9px 0px;
        font-size: 15px;
    }

    .Blog_photos_text button,
    a {
        margin: 8px 0px;
    }

    .Blog_photos_text a,
    h5 {
        font-size: 17px;
    }

    .Blog_photos_text a {
        color: #161c2d;
        text-decoration: none;
        font-size: 13px;
    }

    .footer_Section {
        margin-top: 48px;
        width: 100%;
    }

    .footer_Section_wrapper {
        width: 100%;
        height: max-content;
        padding: 0px 23px
    }

    .footer_content {
        flex-direction: column;
        padding-top: 60px;
        height: max-content;
        width: 100%;
        text-align: center;
    }

    .Logo_section {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: max-content;
    }

    .Logo_section p {
        color: #d1d5db;
        font-size: 15px;
        margin-top: 24px;
        font-family: nunito, sans-serif;
    }

    .company_details,
    h5 {
        margin-top: 20px;
    }

    .list_items ul li {
        margin: 12px 0px;
    }

    .list_items {
        margin-top: 13px;
        font-size: 14px;
    }

    .Newsletter_content {
        margin-top: 3px;
        width: 100%;
        height: max-content;
    }

    .Newsletter_content p {
        color: #e5e7eb;
        font-size: 14px;
    }

    .Newsletter_content label {
        font-size: 14px;
    }

    #emails {
        font-size: 15px;
        padding: 8px 34px;
    }

    .Footer_Sponsors_content {
        flex-direction: row;
        width: 100%;
        margin: 0px;
        padding: 0px 23px
    }

    .Footer_Sponsors_content {
        flex-direction: column;
        width: 100%;
        margin: 0px;
        padding: 0px 23px;
         justify-content: center;
            align-items: center;
        }

    .Footer_Sponsors_content p {
        color: #e5e7eb;
        font-size: 14px;
        font-family: nunito, sans-serif;
    }

    .first_paragraph p {
        font-size: 14px;
        font-family: nunito, sans-serif;
        width: 100%;
        height: max-content;
    }

    .Dream_home {
        margin-bottom: 5px;
        line-height: 27px;
    }

    .header {
        padding: 0px 20px;
        position: relative;
    }

    .video_btn {
        left: 77px;
        display: flex;
        width: 100%;
        margin-top: 0px;
        justify-content: center;
        align-items: center;
        margin-left: 0px;
        position: absolute;
        bottom: 10px;
    }

    .hide_in_laptop_view {
        display: flex;
        /* padding-top: 10px; */
    }

}


/* between 17 inches to 15 inches screen */
/* between 750px to 1080px screen */
/* @media only screen and (min-width: 550px) and (max-width: 1080px) { */

   